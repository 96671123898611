import React from "react";

import {SpecialInitiativeService} from "../../services/SpecialInitiativeService.js";
import Edit from "../../components/dialog/edit.js";
import {
    FormTextField,
    FormTextAreaField,
    FormFileInput, FormDateField, FormSelectField,
} from "../../components/dialog/formField";
import {Paper, Grid, Typography, Button} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import styles from "../../style/styles";
import {I18n} from "../../i18n";
import {Map, Marker, TileLayer} from "react-leaflet";
import classNames from "classnames";
import AddressControl from "../../components/AddressControl";
import GIZButton from "../../components/GIZButton";
import {ResponsiveTable} from "../../components";

const Item = (props) => {
    // Optionally fetch additional data before rendering the form (e.g., select lists)
    const handleGetData = async (service, editorState) => {
        // For now, just return an empty object
        return {};
    };

    const {classes, i18n, i18nCommon, lang, ...rest} = props;

    let renderCommunityLink = (event, item) => {
        const name = `${props.lang === "Eng" ? event.nameEn : event.nameUa}`;
        return <a target="_blank" href={`/communities/view/${event.id}`}>{name}</a>
    };

    return (
        <Edit
            i18n={i18n}
            i18nCommon={i18nCommon}
            handleGetData={handleGetData}
            serviceClass={SpecialInitiativeService}
            {...rest}
            lang={lang}
            classes={classes}
            render={(fieldProps) => (
                <>
                    <Grid container spacing={24} alignItems="flex-start">
                        {/* Left Column: Name / Description fields */}
                        <Grid item container xs={12} md={4} direction="column" spacing={24}>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Grid container spacing={16}>
                                        <Grid item container direction="column" xs={12} spacing={8}>
                                            <Grid item>
                                                <FormTextField
                                                    fieldName="nameEng"
                                                    i18n={i18n}
                                                    {...fieldProps}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FormTextField
                                                    fieldName="nameUa"
                                                    i18n={i18n}
                                                    {...fieldProps}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FormTextAreaField
                                                    fieldName="descriptionEng"
                                                    i18n={i18n}
                                                    {...fieldProps}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FormTextAreaField
                                                    fieldName="descriptionUa"
                                                    i18n={i18n}
                                                    {...fieldProps}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>

                        {/* Middle Column: Dates / Partners / Owner */}
                        <Grid item container xs={12} md={4} direction="column" spacing={24}>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Grid container spacing={16}>
                                        <Grid item container direction="column" xs={12} spacing={8}>
                                            <Grid item>
                                                <FormDateField
                                                    fieldName="startDate"
                                                    i18n={i18n}
                                                    {...fieldProps}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FormDateField
                                                    fieldName="endDate"
                                                    i18n={i18n}
                                                    {...fieldProps}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FormSelectField
                                                    fieldName="partners"
                                                    i18n={i18n}
                                                    selectList={[{ id: 'Ukrainian Partners', en: 'Ukrainian Partners', ua: 'Ukrainian Partners' },
                                                        { id: 'International Partners', en: 'International Partners', ua:'International Partners' },
                                                        { id: 'No partners', en: 'No partners', ua:'No partners' },
                                                        ]}
                                                    {...fieldProps}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FormTextField
                                                    fieldName="partnersName"
                                                    i18n={i18n}
                                                    {...fieldProps}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FormSelectField
                                                    fieldName="format"
                                                    i18n={i18n}
                                                    selectList={[{ id: 'PPF', en: 'PPF', ua: 'PPF' },
                                                        { id: 'Reconstruction', en: 'Reconstruction', ua:'Reconstruction' },
                                                        { id: 'Procurements', en: 'Procurements', ua:'Procurements' },
                                                    ]}
                                                    {...fieldProps}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FormTextField
                                                    fieldName="owner"
                                                    i18n={i18n}
                                                    {...fieldProps}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>

                        {/* Right Column: Attachments (Photo / Report) */}
                        <Grid item container xs={12} md={4} direction="column" spacing={24}>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Grid container spacing={16}>
                                        <Grid item xs={12}>
                                            <Typography variant="h3">
                                                {i18n.attachments || "Attachments"}
                                            </Typography>
                                        </Grid>
                                        <Grid item container direction="column">
                                            <Grid item>
                                                <FormFileInput
                                                    fieldName="photo"
                                                    message={i18n.addPhoto || "Add Photo"}
                                                    icon="Add"
                                                    {...fieldProps}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FormFileInput
                                                    fieldName="report"
                                                    message={i18n.addReport || "Add Report"}
                                                    icon="Add"
                                                    {...fieldProps}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                        {props.isEditable && !fieldProps.formState.id > 0 && (
                            <h3>Please save and open before adding municipalities</h3>
                        )}

                        {props.isEditable && fieldProps.formState.id > 0 && (
                            <>
                                <Grid item container xs={12} md={8} direction="column" spacing={24}>
                                    <Grid item xs={12}>
                                        <Paper className={classes.paper}>
                                            <Grid item container xs={12} direction="row"  spacing={24}>
                                                <Grid item xs={12} md={6}>
                                                    <Map
                                                        className={classNames(classes.map)}
                                                        center={fieldProps.formState.location}
                                                        zoom={fieldProps.formState.zoom}
                                                        dragging={true}
                                                    >
                                                        {props.isEditable && (
                                                            <AddressControl
                                                                className={classes.mapSearch}
                                                                onCoordinatesChange={(
                                                                    latLng,
                                                                    addressParts,
                                                                    address
                                                                ) =>
                                                                    fieldProps.action({
                                                                        fieldName: "newCommunityLocation",
                                                                        value: {latLng, addressParts, address},
                                                                    })
                                                                }
                                                            />
                                                        )}

                                                        <TileLayer
                                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>

                                                        <Marker
                                                            position={fieldProps.formState.location}
                                                        ></Marker>
                                                    </Map>
                                                </Grid>
                                                {fieldProps.formState.newCommunity && (
                                                    <Grid item container xs={12} md={6} spacing={24} direction="column">
                                                        <Grid item xs={12}>
                                                            <FormTextField
                                                                fieldName="newCommunityName"
                                                                i18n={i18n}
                                                                isEditable={false}
                                                                label="Municipality"
                                                                {...fieldProps}
                                                            />
                                                        </Grid>

                                                        {fieldProps.formState.newCommunity.id && (
                                                            <Grid item xs={12}>
                                                                <GIZButton color="primary"
                                                                           onClick={() =>
                                                                               fieldProps.action({
                                                                                   fieldName: "addCommunity",
                                                                                   value: fieldProps.formState.newCommunity,
                                                                               })
                                                                           }>
                                                                  Add Municipality
                                                                </GIZButton>
                                                            </Grid>
                                                        )}

                                                    </Grid>

                                                )}
                                            </Grid>
                                        </Paper>
                                    </Grid>



                                </Grid>

                            </>
                        )}

                        <Grid item xs={12} className={classes.layoutColumn}>
                            {!fieldProps.formState.isTransient && (


                                <div>
                                    <Typography variant="h3">
                                        Municipalities
                                    </Typography>
                                    <ResponsiveTable lang={props.lang} i18n={I18n[props.lang].communities}
                                                     classes={classes}
                                                     items={fieldProps.formState.shortCommunities}
                                                     columns={[
                                                         {id: "id"},
                                                         {
                                                             id: props.lang === "Eng" ? "nameEn" : "nameUa",
                                                             title: "Name",
                                                             displayFunc: (event) => renderCommunityLink(event, fieldProps.formState)
                                                         },
                                                         {
                                                             id: props.lang === "Eng" ? "regionNameEn" : "regionNameUa",
                                                             title: "Region",

                                                         },
                                                     ]}
                                                     renderActions={(event) => {
                                                         if (fieldProps.isEditable) {
                                                             return (
                                                                 <Button
                                                                     size="small"
                                                                     variant="contained"
                                                                     className={classNames("button-cancel", [
                                                                         classes.buttonRemoveSmall,
                                                                     ])}
                                                                     onClick={() =>
                                                                         fieldProps.action({
                                                                             fieldName: "removeCommunity",
                                                                             value: event,
                                                                         })
                                                                     }
                                                                     color="primary"
                                                                 >
                                                                     <i></i>

                                                                 </Button>
                                                             )
                                                         }

                                                     }}
                                    />

                                </div>

                            )}
                        </Grid>
                    </Grid>
                </>
            )}
        />
    );
};

export default withStyles(styles)(Item);
