import React from "react";
import { withStyles } from "@material-ui/core/styles";
import withRoot from "../../withRoot";
import fetchData from "../../utils/fetch";
import "./custom-checkbox.scss";
import classNames from "classnames";
import { each } from "lodash";

const styles = (theme) => ({
  root: {
    display: "inline-flex",
    position: "relative",
    alignItems: "center",
    "& .count": {},
    "& ul, & ul li": {
      listStyleType: "none",
    },
    "& ul": {
      paddingLeft: "20px",
    },
    "& ul ul": {
      paddingLeft: "0",
    },
    "& ul li": {
      marginTop: "5px",
      "& > div": {
        display: "flex",
        alignItems: "center",
      },
    },
    "& .group": {
      display: "none",
      "&.active": {
        display: "block",
      },
    },
    "& .popup": {
      display: "none",
      border: "1px solid #B4C3D1",
      backgroundColor: "#fff",
      borderRadius: "10px",
      width: "245px",
      position: "absolute",
      top: "35px",
      left: "0",
      fontSize: "12px",
      color: "#191970",
      fontWeight: "400",
      whiteSpace: "normal",
      maxHeight: "200px",
      overflowY: "auto",
      zIndex: "2",
      "&.active": {
        display: "block",
      },
    },
    "& .popup-checkbox": {
      color: "#E1EDFB",
      display: "inline-block",
      width: "20px",
      height: "20px",
      backgroundColor: "#E1EDFB",
      borderRadius: "6px",
      marginRight: "10px",
      textAlign: "center",
      fontSize: "28px",
      lineHeight: "17px",
      cursor: "pointer",
      "&.active": {
        backgroundColor: "#778899",
        color: "#fff",
      },
    },
    "& label": {
      lineHeight: "20px",
    },
    "& .main-checkbox": {
      width: "20px",
      height: "20px",
      border: "1px solid #778899",
      borderRadius: "6px",
      position: "relative",
      display: "block",
      margin: "0 6px 0 0",
      cursor: "pointer",
      color: "#fff",
      textAlign: "center",
      fontSize: "28px",
      lineHeight: "15px",
      fontWeight: "400",
      "&.active": {
        backgroundColor: "#778899",
        color: "#fff",
      },
    },
  },
});

const initialState = {
  resetFilters: false,
  isMainActive: false,
  showPopup: false,
  optionsList: [],
  trainingPackageTypesList: [],
  clusterNames: [],
  thematicModulesList: [],
  checkedCount: 0,
  clusterCheckbox: [
    {
      a: [false, false, false, false, false],
      b: [false, false, false, false, false],
      c: [false, false, false, false, false],
    },
    {
      a: [false, false, false, false, false],
      b: [false, false, false, false, false],
      c: [false, false, false, false, false],
    },
    {
      a: [false, false, false, false, false],
      b: [false, false, false, false, false],
      c: [false, false, false, false, false],
    },
    {
      a: [false, false, false, false, false],
      b: [false, false, false, false, false],
      c: [false, false, false, false, false],
    },
    {
      a: [false, false, false, false, false],
      b: [false, false, false, false, false],
      c: [false, false, false, false, false],
    },
    {
      a: [false, false, false, false, false],
      b: [false, false, false, false, false],
      c: [false, false, false, false, false],
    },
  ],
  groups: [
    {
      active: false,
      open: false,
    },
    {
      active: false,
      open: false,
    },
    {
      active: false,
      open: false,
    },
    {
      active: false,
      open: false,
    },
    {
      active: false,
      open: false,
    },
    {
      active: false,
      open: false,
    },
  ],
};

class Filters extends React.Component {
  state = initialState;

  componentDidMount() {
    switch (this.props.id) {
      case "region":
        this.getRegions();
        break;
      case "initiatives":
        this.getEuropeActions();
        break;
      // case "support":
      //   this.getTrainingPackageTypes();
      //   this.getThematicModules();
      //   break;
      default:
        console.error("ID not supported: " + this.props.id);
    }
    document.addEventListener("mousedown", this.handleClickOutside, false);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside, false);
  }

  componentWillReceiveProps(props) {
    if (props.resetFilters) {
      this.setState({ ...this.state, checkedCount: 0 });
    }
  }

  toggleGroupCheckbox = (event) => {
    const groupId = event.target.attributes.getNamedItem("group").value;
    let groupsBuf = this.state.groups;
    groupsBuf[groupId].open = !groupsBuf[groupId].open;
    this.setState({ groups: groupsBuf });
  };

  handleClusterChange = (event) => {
    const groupId = event.target.attributes.getNamedItem("group").value;
    const trainingPackageTypeId = event.target.attributes.getNamedItem("tsp")
      .value;
    const idsList = this.state.thematicModulesList
      .filter((item) => item.cluster === event.target.value)
      .map((item) => `${item.id}_${trainingPackageTypeId}`);
    let subCheckboxes = this.state.clusterCheckbox;
    subCheckboxes[groupId][event.target.value].forEach((item, index) => {
      subCheckboxes[groupId][event.target.value][index] = event.target.checked;
    });
    this.setState({ clusterCheckbox: subCheckboxes });

    let groupsBuf = this.state.groups;
    groupsBuf[groupId].active = !groupsBuf[groupId].active;
    this.setState({ groups: groupsBuf });

    this.props.handleFiltering(
      idsList,
      event.target.checked,
      groupsBuf,
      (newCount) => {
        this.setState({ checkedCount: newCount });
      }
    );
  };

  handleClusterFilterChange = (event) => {
    const groupId = event.target.attributes.getNamedItem("group").value;
    const cluster = event.target.attributes.getNamedItem("cluster").value;
    const index = event.target.attributes.getNamedItem("index").value;
    let subCheckboxes = this.state.clusterCheckbox;
    subCheckboxes[groupId][cluster][index] = event.target.checked;
    this.setState({ clusterCheckbox: subCheckboxes });

    const groupsBuf = this.state.groups;
    subCheckboxes = this.state.clusterCheckbox[groupId];
    groupsBuf[groupId].active = !!(
      subCheckboxes["a"].find((item) => item === true) ||
      subCheckboxes["b"].find((item) => item === true) ||
      subCheckboxes["c"].find((item) => item === true)
    );
    this.setState({ groups: groupsBuf });

    this.props.handleFiltering(
      event.target.value,
      event.target.checked,
      "false",
      (newCount) => {
        this.setState({ checkedCount: newCount });
      }
    );
  };

  handleFilterChange = (event) => {
    const groupId = event.target.attributes.getNamedItem("group");
    if (groupId && groupId.value === "2") {
      const index = event.target.attributes.getNamedItem("index").value;
      let clusterCheckboxBuf = this.state.clusterCheckbox;
      clusterCheckboxBuf[2][index] = !clusterCheckboxBuf[2][index];
      this.setState({ clusterCheckbox: clusterCheckboxBuf });

      const groupsBuf = this.state.groups;
      groupsBuf[2].active = !!this.state.clusterCheckbox[2].find(
        (item) => item === true
      );
      this.setState({ groups: groupsBuf });
    }

    event.target.checked
      ? this.setState({ checkedCount: this.state.checkedCount + 1 })
      : this.setState({ checkedCount: this.state.checkedCount - 1 });
    this.props.handleFiltering(event.target.value, event.target.checked);
  };

  handleClickOutside = (e) => {
    if (!this.node.contains(e.target)) {
      this.closeMainCheckbox();
    }
  };

  closeMainCheckbox = () => {
    this.setState({
      showPopup: false,
      isMainActive: !!this.state.checkedCount,
    });
  };

  toggleMainCheckbox = () => {
    const mainCheckBoxStatus =
      !this.state.showPopup || !!this.state.checkedCount;
    this.setState({
      isMainActive: mainCheckBoxStatus,
      showPopup: !this.state.showPopup,
    });
  };

  async getTrainingPackageTypes() {
    const data = await fetchData("get", "/training_package_types?size=1000");
    const trainingPackageTypes = await data[0];
    const trainingPackageTypesList = [];
    each(trainingPackageTypes, (item) => {
      trainingPackageTypesList.push({
        id: item.id.toString(),
        en: item.nameEn,
        ua: item.nameUa,
      });
    });
    this.setState({ trainingPackageTypesList });
  }

  async getThematicModules() {
    const data = await fetchData("get", "/thematic_modules?size=1000");
    const thematicModules = await data[0];
    const thematicModulesList = [];
    each(thematicModules, (item) => {
      thematicModulesList.push({
        id: "thematic_modules[]=" + item.id.toString(),
        en: item.nameEn,
        ua: item.nameUa,
        cluster: item.cluster,
        clusterNameEn: item.clusterNameEn,
        clusterNameUa: item.clusterNameUa,
        trainingPackageTypeId: item.trainingPackageTypeId,
      });
    });

    this.setState({ thematicModulesList });
  }

  async getEuropeActions() {
    const data = await fetchData("get", "/europe_actions?size=1000");
    const europeActions = await data[0];
    const europeActionsList = [];
    each(europeActions, (item) => {
      europeActionsList.push({
        id: "europe_actions[]=" + item.id.toString(),
        en: item.nameEn,
        ua: item.nameUa,
      });
    });
    this.setState({ optionsList: europeActionsList });
  }

  async getRegions() {
    const data = await fetchData("get", "/regions?size=1000");
    const regions = await data[0];
    let regionsList = [];
    each(regions, (item) => {
      regionsList.push({
        id: "regions[]=" + item.id,
        en: item.en,
        ua: item.ua,
      });
    });
    this.setState({ optionsList: regionsList });
  }

  render() {
    const { classes } = this.props;
    const {
      isMainActive,
      showPopup,
      trainingPackageTypesList,
      thematicModulesList,
      checkedCount,
      clusterCheckbox,
      groups,
    } = this.state;

    let { optionsList } = this.state;

    const label = this.props.lang === "Eng" ? "en" : "ua";
    let clusterNames = [];

    if (this.props.id === "support" && thematicModulesList.length) {
      const clusterNameLabel =
        this.props.lang === "Eng" ? "clusterNameEn" : "clusterNameUa";
      clusterNames = [
        thematicModulesList.find((obj) => obj.cluster === "a")[
          clusterNameLabel
        ],
        thematicModulesList.find((obj) => obj.cluster === "b")[
          clusterNameLabel
        ],
        thematicModulesList.find((obj) => obj.cluster === "c")[
          clusterNameLabel
        ],
      ];
    }
    if (this.props.id === "region" && optionsList.length) {
      optionsList = optionsList.sort(function (a, b) {
        var nameA = a[label].toUpperCase();
        var nameB = b[label].toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    }

    return (
      <div className={classes.root} ref={(node) => (this.node = node)}>
        <div
          className={classNames("main-checkbox", { active: isMainActive })}
          onClick={this.toggleMainCheckbox}
        >
          -
        </div>
        {this.props.children}
        {checkedCount > 0 && <span className="count">({checkedCount})</span>}
        <div className={classNames("popup", { active: showPopup })}>
          {this.props.id === "support" ? (
            <ul>
              {trainingPackageTypesList.map((trainingPackage, groupId) => {
                return (
                  <li key={groupId}>
                    <div>
                      <span
                        className={classNames("popup-checkbox", {
                          active: groups[groupId].active,
                        })}
                        group={groupId}
                        onClick={this.toggleGroupCheckbox}
                      >
                        -
                      </span>
                      <label>{trainingPackage[label]}</label>
                    </div>
                    <ul
                      className={classNames("group", {
                        active: groups[groupId].open,
                      })}
                    >
                      {trainingPackage.id === "1" &&
                        thematicModulesList.map((module, index) => {
                          if (module.trainingPackageTypeId === 1) {
                            return (
                              <li key={index}>
                                <input
                                  className="custom-checkbox"
                                  type="checkbox"
                                  value={`${module.id}_${trainingPackage.id}`}
                                  id={module.id}
                                  group={groupId}
                                  index={index}
                                  onChange={this.handleFilterChange}
                                />
                                <label htmlFor={module.id}>
                                  {module[label]}
                                </label>
                              </li>
                            );
                          } else {
                            return <></>;
                          }
                        })}

                      {trainingPackage.id !== "1" && (
                        <>
                          <li key={groupId}>
                            <input
                              className="custom-checkbox"
                              type="checkbox"
                              value="a"
                              group={groupId}
                              id={`cluster1${groupId}`}
                              tsp={trainingPackage.id}
                              onChange={this.handleClusterChange}
                            />
                            <label htmlFor={`cluster1${groupId}`}>
                              {clusterNames[0]}
                            </label>
                            <ul>
                              {thematicModulesList
                                .filter((obj) => obj.cluster === "a")
                                .map((module, index) => {
                                  return (
                                    <li key={index}>
                                      <input
                                        className="custom-checkbox"
                                        type="checkbox"
                                        checked={
                                          clusterCheckbox[groupId]["a"][index]
                                        }
                                        value={`${module.id}_${trainingPackage.id}`}
                                        id={module.id + groupId}
                                        group={groupId}
                                        cluster="a"
                                        index={index}
                                        onChange={
                                          this.handleClusterFilterChange
                                        }
                                      />
                                      <label htmlFor={module.id + groupId}>
                                        {module[label]}
                                      </label>
                                    </li>
                                  );
                                })}
                            </ul>
                          </li>
                          <li>
                            <input
                              className="custom-checkbox"
                              type="checkbox"
                              value="b"
                              group={groupId}
                              id={`cluster2${groupId}`}
                              tsp={trainingPackage.id}
                              onChange={this.handleClusterChange}
                            />
                            <label htmlFor={`cluster2${groupId}`}>
                              {clusterNames[1]}
                            </label>
                            <ul>
                              {thematicModulesList
                                .filter((obj) => obj.cluster === "b")
                                .map((module, index) => {
                                  return (
                                    <li key={index}>
                                      <input
                                        className="custom-checkbox"
                                        type="checkbox"
                                        checked={
                                          clusterCheckbox[groupId]["b"][index]
                                        }
                                        value={`${module.id}_${trainingPackage.id}`}
                                        id={module.id + groupId}
                                        group={groupId}
                                        cluster="b"
                                        index={index}
                                        onChange={
                                          this.handleClusterFilterChange
                                        }
                                      />
                                      <label htmlFor={module.id + groupId}>
                                        {module[label]}
                                      </label>
                                    </li>
                                  );
                                })}
                            </ul>
                          </li>
                          <li>
                            <input
                              className="custom-checkbox"
                              type="checkbox"
                              value="c"
                              group={groupId}
                              id={`cluster3${groupId}`}
                              tsp={trainingPackage.id}
                              onChange={this.handleClusterChange}
                            />
                            <label htmlFor={`cluster3${groupId}`}>
                              {clusterNames[2]}
                            </label>
                            <ul>
                              {thematicModulesList
                                .filter((obj) => obj.cluster === "c")
                                .map((module, index) => {
                                  return (
                                    <li key={index}>
                                      <input
                                        className="custom-checkbox"
                                        type="checkbox"
                                        checked={
                                          clusterCheckbox[groupId]["c"][index]
                                        }
                                        value={`${module.id}_${trainingPackage.id}`}
                                        id={module.id + groupId}
                                        group={groupId}
                                        cluster="c"
                                        index={index}
                                        onChange={
                                          this.handleClusterFilterChange
                                        }
                                      />
                                      <label htmlFor={module.id + groupId}>
                                        {module[label]}
                                      </label>
                                    </li>
                                  );
                                })}
                            </ul>
                          </li>
                        </>
                      )}
                    </ul>
                  </li>
                );
              })}
            </ul>
          ) : (
            <ul>
              {optionsList.map((item, index) => {
                return (
                  <li key={index}>
                    <input
                      className="custom-checkbox"
                      type="checkbox"
                      onChange={this.handleFilterChange}
                      value={item.id}
                      id={item.id}
                    />
                    <label htmlFor={item.id}>{item[label]}</label>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    );
  }
}

export default withRoot(withStyles(styles)(Filters));
