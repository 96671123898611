import React from "react";
import "url-search-params-polyfill";
import IndexTable from "../../components/table/index.js";
import Item from "./item"; // <-- The form/dialog component for editing/viewing a single SpecialInitiative
import { I18n } from "../../i18n";
import { SpecialInitiativeService } from "../../services/SpecialInitiativeService";

class SpecialInitiative extends React.Component {
    render() {
        // Adjust this to match your backend endpoint
        const path = "special-initiatives";

        // i18n properties for translations
        // Make sure you have `special_initiatives` definitions in I18n
        const i18nProps = {
            i18n: I18n[this.props.lang].special_initiatives,
            i18nCommon: I18n[this.props.lang].common,
        };

        return (
            <IndexTable
                {...this.props}
                {...i18nProps}
                serviceClass={SpecialInitiativeService}
                path={path}
                columns={[
                    { id: "id", title: "ID" },
                    // Adjust column IDs/titles to match SpecialInitiative fields
                    { id: "nameEng", title: "Name (EN)" },
                    { id: "nameUa", title: "Name (UA)" },
                    { id: "startDate", title: "Start Date" },
                    { id: "endDate", title: "End Date" },
                ]}
                // Callback for Edit
                onEditItem={(itemId, item, handleItemSave, props) => {
                    return (
                        <Item
                            item={item}
                            itemId={itemId}
                            indexPath={path}
                            handleItemSave={handleItemSave}
                            isEditable={true}
                            {...i18nProps}
                            {...this.props}
                        />
                    );
                }}
                // Callback for View
                onViewItem={(itemId, item, props) => {
                    return (
                        <Item
                            item={item}
                            itemId={itemId}
                            indexPath={path}
                            isEditable={false}
                            {...i18nProps}
                            {...this.props}
                        />
                    );
                }}
            />
        );
    }
}

export default SpecialInitiative;
