import React from "react";
import {observer} from "mobx-react";
import {I18n} from "../../i18n";
import EventService from "../../services/EventService.js";
import Edit from "../../components/dialog/edit.js";
import AddressControl from "../../components/AddressControl.js";
import {
    FormNumberField,
    FormDateField,
    FormTextField,
    FormTextAreaField,
    FormSelectField,
    FormMultiSelectField,
    FormFileInput,
} from "../../components/dialog/formField";
import {
    Paper,
    InputLabel,
    Typography,
    Grid,
    Snackbar,
    Hidden,
    IconButton
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {Map, TileLayer, Marker} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/assets/css/leaflet.css";
import categoryCaptions from "../../utils/categoryCaptions";
import {withStyles} from "@material-ui/core/styles";
import styles from "../../style/styles";
import {StoreContext} from "../../stores/context";
import {Settings} from "../../config.js";
import GIZButton from "../../components/GIZButton";

class Item extends React.Component {
    static contextType = StoreContext;

    constructor(props) {
        super();
    }

    async handleGetData(service, editorState) {
        let eventFormatsRaw = await service.GetAllSelectOptions(
            "formats",
            editorState.formatId
        );

        eventFormatsRaw = eventFormatsRaw.filter((item) => {
            if (
                item.id !== editorState.formatId &&
                (!item.active ||
                    (editorState.isFirstPhase && item.langKey === "STRATEGIC_SESSION"))
            ) {
                return false;
            } else {
                return true;
            }
        });

        const regions = await service.GetRegions();
        let categoriesList = (await service.GetSelectOptions(
            "categories",
            editorState.categories,
            undefined,
            true,
            "all=true"
        ))
            .filter(x => x.active || (editorState.categories && editorState.categories.some(y => y.id === x.id)))
            .sort((a, b) => (a.langKey > b.langKey) - (a.langKey < b.langKey));

        const organizersList = await service.GetSelectOptions(
            "organizers",
            editorState.organizers
        );
        const targetGroupsList = await service.GetSelectOptions(
            "target_groups",
            editorState.targetGroups
        );
        const targetLevelsList = await service.GetSelectOptions(
            "target_levels",
            editorState.targetLevels
        );
        const fundingSourcesList = await service.GetSelectOptions(
            "funding_sources",
            editorState.fundingSources
        );
        const eventCharactersList = await service.GetEventCharacters(
            editorState.eventCharacters,
            I18n
        );

        const crossCuttingTopicsList = await service.GetCrossCuttingTopics();

        const trainingPackageTypesList = (
            await service.GetTrainingPackageTypes(
                editorState.restrictCTP,
                editorState.trainingPackageType
            )
        );

        const thematicModulesList = await service.GetThematicModules();

        const europeActionsList = await service.GetAllSelectOptions(
            "europe_actions",
            editorState.europeActions
        );
        europeActionsList.unshift({
            id: 0,
            label: I18n[this.props.lang].common.no,
        });

        let users = [];
        if (editorState.showOwner) {
            users = await service.GetEventOwners(editorState.fake);
        }

        const trainersList = editorState.isGuest ? new Array() : await service.GetTrainers();
        const facilitatorsList = editorState.isGuest ? new Array() : await service.GetFacilitators();
        const expertsList = editorState.isGuest ? new Array() : await service.GetExperts();

        const state = {
            eventFormatsRaw,
            regions,
            categoriesList,
            organizersList,
            targetGroupsList,
            targetLevelsList,
            fundingSourcesList,
            eventCharactersList,
            trainingPackageTypesList,
            thematicModulesList,
            europeActionsList,
            users,
            trainersList,
            facilitatorsList,
            expertsList,
            crossCuttingTopicsList,
        };

        return state;
    }

    // there are state modifications which depend on loaded lists
    // those may be executed here
    onAfterGetData(service, state) {
        //let newState = service.SetCategories(state, state.categories);
        let newState = service.SetTrainingPackageTypes(
            state,
            state.trainingPackageTypes
        );

        // trigger setStartDate after loaded list of eventFormats,
        // to filter list according date
        newState = service.SetStartDate(newState, state.startDate);

        return newState;
    }

    formatGroupLabel = (data) => {
        let color = "#6495ed";

        if (data.label.includes("Cluster B") || data.label.includes("Кластер B")) {
            color = "rgb(253, 187, 45)";
        }
        if (data.label.includes("Cluster C") || data.label.includes("Кластер C")) {
            color = "grey";
        }

        return (
            <div className="groupStyles" style={{backgroundColor: color}}>
                <span>{data.label}</span>
            </div>
        );
    };

    render = () => {
        const {langStore, userStore} = this.context;
        const i18n = langStore.events;
        const {classes, ...rest} = {i18n, ...this.props};

        if (this.props.itemId === undefined && userStore.isFirstPhase) {
            return (
                <Snackbar
                    anchorOrigin={{vertical: "top", horizontal: "center"}}
                    message={
                        <span id="snackbar-error-id">
              Unable to create an event for first phase
            </span>
                    }
                    open
                    ContentProps={{
                        "aria-describedby": "snackbar-error-id",
                    }}
                />
            );
        }

        return (
            <Edit
                i18n={i18n}
                handleGetData={this.handleGetData}
                onAfterGetData={this.onAfterGetData}
                serviceClass={EventService}
                classes={classes}
                {...rest}
                render={(fieldProps) => (
                    <Paper className={classes.paper}>
                        <Hidden only={['xs', 'sm']}>

                            <Grid container>
                                <Grid item container direction="column" xs={12} sm={6} md={3}
                                      className={classes.layoutColumn}>
                                    <Grid item>
                                        <FormTextAreaField fieldName="nameEn" {...fieldProps}
                                                           tabIndex={Settings.ConsultationMode ? 1 : 0}/>
                                    </Grid>
                                    {fieldProps.formState.isNotThirdPhase &&
                                        fieldProps.formState.showEventCharacters && (
                                            <Grid item xs className={classes.noFlexGrow}>
                                                <FormMultiSelectField
                                                    fieldName="eventCharacters"
                                                    {...fieldProps}
                                                />
                                            </Grid>
                                        )}

                                    {fieldProps.formState.isNotThirdPhase && (
                                        <>
                                            <Grid item>
                                                <FormTextAreaField
                                                    fieldName="eventAddress"
                                                    {...fieldProps}
                                                />
                                            </Grid>

                                            <Grid item>
                                                <FormTextField
                                                    isDisabled={true}
                                                    fieldName="placeOfEventCoordinates"
                                                    value={fieldProps.formState.coordString}
                                                    {...fieldProps}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Map
                                                    className={classes.map}
                                                    center={fieldProps.formState.position}
                                                    zoom={fieldProps.formState.zoom}
                                                >
                                                    {this.props.isEditable && (
                                                        <AddressControl
                                                            className={classes.mapSearch}
                                                            onCoordinatesChange={(
                                                                latLng,
                                                                addressParts,
                                                                address
                                                            ) =>
                                                                fieldProps.action({
                                                                    fieldName: "mapSearch",
                                                                    value: {latLng, addressParts, address},
                                                                })
                                                            }
                                                        />
                                                    )}
                                                    <TileLayer
                                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                                                    <Marker
                                                        position={fieldProps.formState.position}
                                                    ></Marker>
                                                </Map>
                                            </Grid></>)}
                                </Grid>

                                <Grid item container direction="column" xs={12} sm={6} md={3}
                                      className={classes.layoutColumn}>
                                    <Grid item>
                                        <FormTextAreaField {...fieldProps} fieldName="nameUa"
                                                           tabIndex={Settings.ConsultationMode ? 2 : 0}/>
                                    </Grid>
                                    <Grid item>
                                        <FormDateField fieldName="startDate" {...fieldProps}
                                                       tabIndex={Settings.ConsultationMode ? 3 : 0}/>
                                    </Grid>
                                    <Grid item>
                                        <FormSelectField
                                            fieldName="regionId"
                                            selectList={fieldProps.formState.regions}
                                            label={i18n.region}
                                            tabIndex={Settings.ConsultationMode ? 4 : 0}
                                            {...fieldProps}
                                        />
                                    </Grid>

                                    <Grid item>
                                        <FormSelectField
                                            fieldName="formatId"
                                            label={i18n.eventFormat}
                                            selectList={fieldProps.formState.eventFormats}
                                            tabIndex={Settings.ConsultationMode ? 5 : 0}
                                            {...fieldProps}
                                        />
                                    </Grid>
                                    <Grid item xs className={classes.noFlexGrow}>
                                        <FormMultiSelectField
                                            fieldName="organizers"
                                            {...fieldProps}
                                        />
                                    </Grid>

                                    {fieldProps.formState.isNotThirdPhase && fieldProps.formState.showExpectedNumberOfParticipants && (
                                        <Grid item>
                                            <FormNumberField
                                                fieldName="expectedNumberOfParticipants"
                                                label={i18n.expectedParticipants}
                                                {...fieldProps}
                                            />
                                        </Grid>
                                    )}
                                    {fieldProps.formState.showActualNumberOfParticipants && (
                                        <>
                                            <Grid item>
                                                <FormNumberField
                                                    fieldName="actualNumberOfParticipants"
                                                    {...fieldProps}
                                                />
                                            </Grid>
                                            {fieldProps.formState.isThirdPhase && (
                                                <Grid item>
                                                    <FormNumberField
                                                        fieldName="numberWomenParticipated"
                                                        {...fieldProps}
                                                    />
                                                </Grid>
                                            )}
                                        </>
                                    )}

                                    {fieldProps.formState.isNotThirdPhase && (
                                        <>
                                            <Grid item>
                                                <InputLabel>
                                                    {I18n[this.props.lang].events.mediaReferences}
                                                </InputLabel>

                                                <Grid item container direction="column">
                                                    {fieldProps.formState.mediaLinks.map(
                                                        (mediaReference, idx) => (
                                                            <Grid
                                                                key={idx}
                                                                item
                                                                container
                                                                direction="column"

                                                            >
                                                                <Grid
                                                                    item
                                                                    container
                                                                    direction="column"

                                                                >
                                                                    <Grid item>
                                                                        <button
                                                                            className={classes.formButton}
                                                                            type="button"
                                                                            onClick={(e) => {
                                                                                fieldProps.action({
                                                                                    fieldName: "mediaReference_Remove",
                                                                                    value: {idx: idx},
                                                                                });
                                                                            }}
                                                                        >
                                                                            <CloseIcon/>
                                                                        </button>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <FormTextField
                                                                            fieldName={`URL_${idx}`}
                                                                            hideLabel={true}
                                                                            placeholder={`URL`}
                                                                            value={mediaReference.url}
                                                                            onChange={(e) => {
                                                                                fieldProps.action({
                                                                                    fieldName: "mediaReference_ChangeUrl",
                                                                                    value: {
                                                                                        idx: idx,
                                                                                        url: e.currentTarget.value,
                                                                                    },
                                                                                });
                                                                            }}
                                                                            {...fieldProps}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item>
                                                                    <FormTextField
                                                                        hideLabel={true}
                                                                        fieldName={`TILE_${idx}`}
                                                                        placeholder={`Title`}
                                                                        value={mediaReference.title}
                                                                        onChange={(e) => {
                                                                            fieldProps.action({
                                                                                fieldName: "mediaReference_ChangeTitle",
                                                                                value: {
                                                                                    idx: idx,
                                                                                    title: e.currentTarget.value,
                                                                                },
                                                                            });
                                                                        }}
                                                                        {...fieldProps}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        )
                                                    )}
                                                    <Grid item>
                                                        <button
                                                            type="button"
                                                            className={classes.formButton}
                                                            onClick={(e) => {
                                                                fieldProps.action({
                                                                    fieldName: "mediaReference_Add",
                                                                });
                                                            }}
                                                        >
                                                            {I18n[this.props.lang].common.add}
                                                        </button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <FormTextAreaField
                                                    fieldName="internalFeedback"
                                                    {...fieldProps}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FormTextAreaField
                                                    fieldName="responsiblePerson"
                                                    {...fieldProps}
                                                />
                                            </Grid>
                                        </>
                                    )}

                                    {fieldProps.formState.showOwner && (
                                        <Grid item>
                                            <FormSelectField
                                                fieldName="ownerId"
                                                selectList={fieldProps.formState.users}
                                                label={i18n.owner}
                                                {...fieldProps}
                                            />
                                        </Grid>
                                    )}
                                </Grid>

                                <Grid
                                    item
                                    container
                                    direction="column"
                                    xs={12} sm={6} md={3}
                                    className={classes.layoutColumn}
                                >
                                    <Grid item>
                                        <FormTextAreaField fieldName="descEn" {...fieldProps} />
                                    </Grid>
                                    <Grid item>
                                        <FormDateField fieldName="endDate" {...fieldProps} />
                                    </Grid>
                                    <Grid item xs className={classes.noFlexGrow}>
                                        <FormMultiSelectField
                                            fieldName="targetGroups"
                                            {...fieldProps}
                                        />
                                    </Grid>
                                    <Grid item xs className={classes.noFlexGrow}>
                                        <FormMultiSelectField
                                            fieldName="targetLevels"
                                            {...fieldProps}
                                        />
                                    </Grid>
                                    {fieldProps.formState.isNotThirdPhase && (
                                        <>
                                            {fieldProps.formState.showTrainers && (
                                                <Grid item xs className={classes.noFlexGrow}>
                                                    <FormMultiSelectField
                                                        fieldName="trainers"
                                                        {...fieldProps}
                                                    />
                                                </Grid>
                                            )}
                                            {fieldProps.formState.showFacilitators && (
                                                <Grid item>
                                                    <FormMultiSelectField
                                                        fieldName="facilitators"
                                                        {...fieldProps}
                                                    />
                                                </Grid>
                                            )}
                                        </>)}

                                    {Settings.Tenant.Is3E4U && (
                                        <Grid item>
                                            <FormMultiSelectField
                                                fieldName="experts"
                                                {...fieldProps}
                                            />
                                        </Grid>
                                    )}

                                    {fieldProps.formState.showCategory && (
                                        <Grid item xs className={classes.noFlexGrow}>
                                            <FormMultiSelectField
                                                label={categoryCaptions(this.props.user).categories}
                                                fieldName="categories"
                                                {...fieldProps}
                                            />
                                            <div>
                                                {fieldProps.formState.subCategories
                                                    .filter((s) => s.list.length > 0)
                                                    .map((item, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <Typography variant="h4">
                                                                    {item.title}:
                                                                </Typography>
                                                                <ul>
                                                                    {item.list.map((listItem, index) => {
                                                                        return (
                                                                            <li key={index}>
                                                                                <Typography variant="body2">
                                                                                    {listItem}
                                                                                </Typography>
                                                                            </li>
                                                                        );
                                                                    })}
                                                                </ul>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                            </div>
                                        </Grid>
                                    )}

                                    {fieldProps.formState.isNotThirdPhase && (

                                        <Grid item>
                                            <FormSelectField
                                                fieldName="kind"
                                                selectList={[
                                                    {
                                                        label: i18n.hybrid,
                                                        value: "hybrid",
                                                        id: "hybrid",
                                                    },
                                                    {
                                                        label: i18n.online,
                                                        value: "online",
                                                        id: "online",
                                                    },
                                                    {
                                                        label: i18n.live,
                                                        value: "live",
                                                        id: "live",
                                                    },
                                                    {
                                                        label: i18n.alternative,
                                                        value: "alternative",
                                                        id: "alternative",
                                                    },
                                                ]}
                                                {...fieldProps}
                                            />
                                        </Grid>)}
                                </Grid>

                                <Grid item container direction="column" xs={12} md={3} className={classes.layoutColumn}>
                                    <Grid item>
                                        <FormTextAreaField fieldName="descUa" {...fieldProps} />
                                    </Grid>
                                    {fieldProps.formState.isNotThirdPhase && (
                                        <Grid item xs className={classes.noFlexGrow}>
                                            <FormMultiSelectField
                                                fieldName="fundingSources"
                                                {...fieldProps}
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs className={classes.noFlexGrow}>
                                        <FormMultiSelectField
                                            fieldName="crossCuttingTopics"
                                            {...fieldProps}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FormFileInput
                                            fieldName="eventReport"
                                            message="Add report"
                                            icon="Add"
                                            {...fieldProps}
                                        />
                                        {Settings.Tenant.Is3E4U && (
                                            <FormTextAreaField
                                                hideLabel={true}
                                                fieldName="reportText"
                                                {...fieldProps}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item>
                                        <FormFileInput
                                            fieldName="eventFoto"
                                            message="Add photo"
                                            icon="Add"
                                            {...fieldProps}
                                        />
                                    </Grid>

                                    {fieldProps.formState.showImplementedThroughTsp && (
                                        <Grid item>
                                            <FormSelectField
                                                fieldName="showThematicPackages"
                                                label={fieldProps.formState.showNetworkTitle
                                                    ? i18n.thematicPackagesAndNetworkTitle
                                                    : i18n.thematicPackagesTitle}
                                                {...fieldProps}
                                                isDisabled={fieldProps.formState.isDisabledTSP}
                                                selectList={[
                                                    {
                                                        label: "",
                                                        id: null
                                                    },
                                                    {label: I18n[this.props.lang].common.yes, id: true},
                                                    {
                                                        label: I18n[this.props.lang].common.no,
                                                        id: false,
                                                    },
                                                ]}
                                                value={fieldProps.formState.showThematicPackages}
                                            />
                                        </Grid>
                                    )}
                                    {fieldProps.formState.showThematicPackages && (
                                        <>
                                            <Grid item>
                                                <FormSelectField
                                                    fieldName="trainingPackageTypes"
                                                    label={i18n.trainingPackages}
                                                    isDisabled={fieldProps.formState.isDisabledTSP}
                                                    {...fieldProps}
                                                />
                                            </Grid>
                                            {fieldProps.formState.showThematicModules && (
                                                <Grid item>
                                                    <FormSelectField
                                                        fieldName="thematicModules"
                                                        selectList={
                                                            fieldProps.formState.thematicModulesClustered
                                                        }
                                                        isDisabled={fieldProps.formState.isDisabledTSP}
                                                        formatGroupLabel={this.formatGroupLabel}
                                                        grouping={true}
                                                        {...fieldProps}
                                                    />
                                                </Grid>)}
                                        </>
                                    )}

                                    {fieldProps.formState.showRegionalInitiatives && (
                                        <Grid item>
                                            <FormSelectField
                                                fieldName="europeActions"
                                                label={i18n.europeActoinsTitle}
                                                {...fieldProps}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>

                        </Hidden>
                        <Hidden mdUp>
                            <Grid container xs={12} direction="column" className={classes.layoutColumn}>
                                <Grid item>
                                    <FormTextAreaField fieldName="nameEn" {...fieldProps}
                                                       tabIndex={Settings.ConsultationMode ? 1 : 0}/>
                                </Grid>
                                <Grid item>
                                    <FormTextAreaField {...fieldProps} fieldName="nameUa"
                                                       tabIndex={Settings.ConsultationMode ? 2 : 0}/>
                                </Grid>
                                <Grid item>
                                    <FormTextAreaField fieldName="descEn" {...fieldProps} />
                                </Grid>
                                <Grid item>
                                    <FormTextAreaField fieldName="descUa" {...fieldProps} />
                                </Grid>
                                <Grid item>
                                    <FormDateField fieldName="startDate" {...fieldProps}
                                                   tabIndex={Settings.ConsultationMode ? 3 : 0}/>
                                </Grid>
                                <Grid item>
                                    <FormDateField fieldName="endDate" {...fieldProps} />
                                </Grid>

                                {fieldProps.formState.isNotThirdPhase && fieldProps.formState.showExpectedNumberOfParticipants && (
                                    <Grid item>
                                        <FormNumberField
                                            fieldName="expectedNumberOfParticipants"
                                            label={i18n.expectedParticipants}
                                            {...fieldProps}
                                        />
                                    </Grid>
                                )}
                                {fieldProps.formState.showActualNumberOfParticipants && (
                                    <><Grid item>
                                        <FormNumberField
                                            fieldName="actualNumberOfParticipants"
                                            {...fieldProps}
                                        />
                                    </Grid>
                                        {fieldProps.formState.isThirdPhase && (
                                            <Grid item>
                                                <FormNumberField
                                                    fieldName="numberWomenParticipated"
                                                    {...fieldProps}
                                                />

                                            </Grid>
                                        )}</>
                                )}
                                <Grid item>
                                    <FormSelectField
                                        fieldName="formatId"
                                        label={i18n.eventFormat}
                                        selectList={fieldProps.formState.eventFormats}
                                        tabIndex={Settings.ConsultationMode ? 5 : 0}
                                        {...fieldProps}
                                    />
                                </Grid>
                                <Grid item>
                                    <FormFileInput
                                        fieldName="eventReport"
                                        message="Add report"
                                        icon="Add"
                                        {...fieldProps}
                                    />
                                </Grid>
                                <Grid item>
                                    <FormFileInput
                                        fieldName="eventFoto"
                                        message="Add photo"
                                        icon="Add"
                                        {...fieldProps}
                                    />
                                </Grid>
                                <Grid item>
                                    <FormSelectField
                                        fieldName="regionId"
                                        selectList={fieldProps.formState.regions}
                                        label={i18n.region}
                                        tabIndex={Settings.ConsultationMode ? 4 : 0}
                                        {...fieldProps}
                                    />
                                </Grid>

                                {fieldProps.formState.isNotThirdPhase && (
                                    <>
                                        <Grid item>
                                            <FormTextAreaField
                                                fieldName="eventAddress"
                                                {...fieldProps}
                                            />
                                        </Grid>

                                        <Grid item>
                                            <FormTextField
                                                isDisabled={true}
                                                fieldName="placeOfEventCoordinates"
                                                value={fieldProps.formState.coordString}
                                                {...fieldProps}
                                            />
                                        </Grid>

                                        <Grid item>
                                            <Map
                                                className={classes.map}
                                                center={fieldProps.formState.position}
                                                zoom={fieldProps.formState.zoom}
                                            >
                                                {this.props.isEditable && (
                                                    <AddressControl
                                                        className={classes.mapSearch}
                                                        onCoordinatesChange={(
                                                            latLng,
                                                            addressParts,
                                                            address
                                                        ) =>
                                                            fieldProps.action({
                                                                fieldName: "mapSearch",
                                                                value: {latLng, addressParts, address},
                                                            })
                                                        }
                                                    />
                                                )}
                                                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                                                <Marker
                                                    position={fieldProps.formState.position}
                                                ></Marker>
                                            </Map>
                                        </Grid>
                                    </>
                                )}
                                <Grid item xs className={classes.noFlexGrow}>
                                    <FormMultiSelectField
                                        fieldName="organizers"
                                        {...fieldProps}
                                    />
                                </Grid>

                                {fieldProps.formState.isNotThirdPhase && (
                                    <>
                                        {fieldProps.formState.showTrainers && (
                                            <Grid item xs className={classes.noFlexGrow}>
                                                <FormMultiSelectField
                                                    fieldName="trainers"
                                                    {...fieldProps}
                                                />
                                            </Grid>
                                        )}


                                        {fieldProps.formState.showFacilitators && (
                                            <Grid item>
                                                <FormMultiSelectField
                                                    fieldName="facilitators"
                                                    {...fieldProps}
                                                />
                                            </Grid>
                                        )}
                                    </>
                                )}
                                {Settings.Tenant.Is3E4U && (
                                    <Grid item>
                                        <FormMultiSelectField
                                            fieldName="experts"
                                            {...fieldProps}
                                        />
                                    </Grid>
                                )}

                                {fieldProps.formState.showCategory && (
                                    <Grid item xs className={classes.noFlexGrow}>
                                        <FormMultiSelectField
                                            label={categoryCaptions(this.props.user).categories}
                                            fieldName="categories"
                                            {...fieldProps}
                                        />
                                        <div>
                                            {fieldProps.formState.subCategories
                                                .filter((s) => s.list.length > 0)
                                                .map((item, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <Typography variant="h4">
                                                                {item.title}:
                                                            </Typography>
                                                            <ul>
                                                                {item.list.map((listItem, index) => {
                                                                    return (
                                                                        <li key={index}>
                                                                            <Typography variant="body2">
                                                                                {listItem}
                                                                            </Typography>
                                                                        </li>
                                                                    );
                                                                })}
                                                            </ul>
                                                        </React.Fragment>
                                                    );
                                                })}
                                        </div>
                                    </Grid>
                                )}

                                {fieldProps.formState.isNotThirdPhase && (
                                    <>
                                        <Grid item>
                                            <FormSelectField
                                                fieldName="kind"
                                                selectList={[
                                                    {
                                                        label: i18n.hybrid,
                                                        value: "hybrid",
                                                        id: "hybrid",
                                                    },
                                                    {
                                                        label: i18n.online,
                                                        value: "online",
                                                        id: "online",
                                                    },
                                                    {
                                                        label: i18n.live,
                                                        value: "live",
                                                        id: "live",
                                                    },
                                                    {
                                                        label: i18n.alternative,
                                                        value: "alternative",
                                                        id: "alternative",
                                                    },
                                                ]}
                                                {...fieldProps}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <InputLabel>
                                                {I18n[this.props.lang].events.mediaReferences}
                                            </InputLabel>

                                            <Grid item container direction="column">
                                                {fieldProps.formState.mediaLinks.map(
                                                    (mediaReference, idx) => (
                                                        <Grid
                                                            key={idx}
                                                            item
                                                            container
                                                            direction="column"

                                                        >
                                                            <Grid
                                                                item
                                                                container
                                                                direction="column"
                                                                className={classes.mediaItemList}
                                                            >
                                                                <Grid item>
                                                                    <button
                                                                        className={classes.formButton}
                                                                        type="button"
                                                                        onClick={(e) => {
                                                                            fieldProps.action({
                                                                                fieldName: "mediaReference_Remove",
                                                                                value: {idx: idx},
                                                                            });
                                                                        }}
                                                                    >
                                                                        <CloseIcon/>
                                                                    </button>
                                                                </Grid>
                                                                <Grid item className={classes.mediaItemItem}>
                                                                    <FormTextField
                                                                        fieldName={`URL_${idx}`}
                                                                        hideLabel={true}
                                                                        placeholder={`URL`}
                                                                        value={mediaReference.url}
                                                                        onChange={(e) => {
                                                                            fieldProps.action({
                                                                                fieldName: "mediaReference_ChangeUrl",
                                                                                value: {
                                                                                    idx: idx,
                                                                                    url: e.currentTarget.value,
                                                                                },
                                                                            });
                                                                        }}
                                                                        {...fieldProps}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item className={classes.mediaItemItem}>
                                                                <FormTextField
                                                                    hideLabel={true}
                                                                    fieldName={`TILE_${idx}`}
                                                                    placeholder={`Title`}
                                                                    value={mediaReference.title}
                                                                    onChange={(e) => {
                                                                        fieldProps.action({
                                                                            fieldName: "mediaReference_ChangeTitle",
                                                                            value: {
                                                                                idx: idx,
                                                                                title: e.currentTarget.value,
                                                                            },
                                                                        });
                                                                    }}
                                                                    {...fieldProps}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                )}
                                                <Grid item>
                                                    <GIZButton
                                                        icon="Add"
                                                        color="tertiary"
                                                        onClick={(e) => {
                                                            fieldProps.action({
                                                                fieldName: "mediaReference_Add",
                                                            });
                                                        }}
                                                    >
                                                        {I18n[this.props.lang].common.add}
                                                    </GIZButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {fieldProps.formState.isNotThirdPhase && (
                                            <Grid item xs className={classes.noFlexGrow}>
                                                <FormMultiSelectField
                                                    fieldName="fundingSources"
                                                    {...fieldProps}
                                                />
                                            </Grid>
                                        )}
                                    </>
                                )}
                                <Grid item xs className={classes.noFlexGrow}>
                                    <FormMultiSelectField
                                        fieldName="crossCuttingTopics"
                                        {...fieldProps}
                                    />
                                </Grid>

                                <Grid item xs className={classes.noFlexGrow}>
                                    <FormMultiSelectField
                                        fieldName="targetGroups"
                                        {...fieldProps}
                                    />
                                </Grid>

                                <Grid item xs className={classes.noFlexGrow}>
                                    <FormMultiSelectField
                                        fieldName="targetLevels"
                                        {...fieldProps}
                                    />
                                </Grid>

                                {fieldProps.formState.isNotThirdPhase && (
                                    <>
                                        <Grid item>
                                            <FormTextAreaField
                                                fieldName="internalFeedback"
                                                {...fieldProps}
                                            />
                                        </Grid>

                                        <Grid item>
                                            <FormTextAreaField
                                                fieldName="responsiblePerson"
                                                {...fieldProps}
                                            />
                                        </Grid>
                                    </>)}

                                {fieldProps.formState.showOwner && (
                                    <Grid item>
                                        <FormSelectField
                                            fieldName="ownerId"
                                            selectList={fieldProps.formState.users}
                                            label={i18n.owner}
                                            {...fieldProps}
                                        />
                                    </Grid>
                                )}

                                {fieldProps.formState.isNotThirdPhase && fieldProps.formState.showEventCharacters && (
                                    <Grid item xs className={classes.noFlexGrow}>
                                        <FormMultiSelectField
                                            fieldName="eventCharacters"
                                            {...fieldProps}
                                        />
                                    </Grid>
                                )}

                                {fieldProps.formState.showImplementedThroughTsp && (
                                    <Grid item>
                                        <FormSelectField
                                            fieldName="showThematicPackages"
                                            label={fieldProps.formState.showNetworkTitle
                                                ? i18n.thematicPackagesAndNetworkTitle
                                                : i18n.thematicPackagesTitle}
                                            {...fieldProps}
                                            isDisabled={fieldProps.formState.isDisabledTSP}
                                            selectList={[
                                                {
                                                    label: "",
                                                    id: null
                                                },
                                                {
                                                    label: I18n[this.props.lang].common.yes,
                                                    id: true
                                                },
                                                {
                                                    label: I18n[this.props.lang].common.no,
                                                    id: false,
                                                },
                                            ]}
                                            value={fieldProps.formState.showThematicPackages}
                                        />
                                    </Grid>
                                )}
                                {fieldProps.formState.showThematicPackages && (
                                    <>
                                        <Grid item>
                                            <FormSelectField
                                                fieldName="trainingPackageTypes"
                                                label={i18n.trainingPackages}
                                                isDisabled={fieldProps.formState.isDisabledTSP}
                                                {...fieldProps}
                                            />
                                        </Grid>
                                        {fieldProps.formState.showThematicModules && (
                                            <Grid item>
                                                <FormSelectField
                                                    fieldName="thematicModules"
                                                    selectList={
                                                        fieldProps.formState.thematicModulesClustered
                                                    }
                                                    isDisabled={fieldProps.formState.isDisabledTSP}
                                                    formatGroupLabel={this.formatGroupLabel}
                                                    grouping={true}
                                                    {...fieldProps}
                                                />
                                            </Grid>)}
                                    </>
                                )}

                                {fieldProps.formState.showRegionalInitiatives && (
                                    <Grid item>
                                        <FormSelectField
                                            fieldName="europeActions"
                                            label={i18n.europeActoinsTitle}
                                            {...fieldProps}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Hidden>
                    </Paper>

                )}
            />
        );
    };
}

export default observer(Item);
